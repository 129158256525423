.btn {
  @apply py-2 px-5 text-white rounded cursor-pointer;
}
.btn:hover{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.btn-dark {
  @apply bg-gray-900 px-4 py-3 capitalize font-medium;
}
.btn-indigo {
  @apply bg-indigo-600 text-white capitalize font-medium px-4 py-3 cursor-pointer !important;
}
.btn-warning {
  @apply bg-orange-400 py-[6px];
}
.btn-danger {
  @apply bg-rose-500 py-[6px];
}

.btn-active{
  @apply bg-themeColor !text-white
}