.toggle-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
  }
  
  .toggle-switch {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .toggle-switch p {
    margin-right: 10px;
    font-size: 16px;
    color: #333; /* Adjust text color as needed */
  }
  
  .slider {
    position: relative;
    width: 40px;
    height: 20px;
    background-color: #ccc;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .slider::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
  }
  
  .slider.active {
    background-color: #007bff;
  }
  
  .slider.active::before {
    transform: translateX(20px);
    
  }
  