@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./styles/buttons.css";
@import "./styles/form.css";
@import "./styles/styles.css";
@import "./styles/nav.css";
@import "./styles/header.css";
@import "./styles/table.css";

@layer base {
  *,
  ::before,
  ::after {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
  }
}

html {
  scroll-behavior: smooth;
}
